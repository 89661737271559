<template>
  <BaseModal
    ref="modal"
    title="Video"
    max-width="tw-max-w-4xl"
  >
    <div class="cont">
      <!-- YouTube Video -->
      <div v-if="isYoutubeVideo" class="video-wrapper">
        <div class="youtube-responsive">
          <iframe
            :src="embeddedVideoUrl"
            allow="fullscreen; autoplay"
            allowfullscreen
          />
        </div>
      </div>

      <!-- MP4 Video -->
      <div v-else class="tw-flex tw-justify-center tw-items-center tw-text-center">
        <video
          ref="videoPlayer"
          class="tw-mx-auto tw-responsive-video"
          controls
          autoplay
          playsinline
          onloadstart="this.volume=0.5"
          :src="videoUrl"
          @loadedmetadata="setVideoAspect"
        />
      </div>
    </div>
  </BaseModal>
</template>

<script>
export default {
  name: 'VideoPopUp',
  props: {
    videoUrl: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isPortrait: false
    }
  },
  computed: {
    isYoutubeVideo () {
      return this.parseYouTubeVideoId(this.videoUrl) !== ''
    },
    embeddedVideoUrl () {
      const id = this.parseYouTubeVideoId(this.videoUrl)
      return id ? `https://www.youtube.com/embed/${id}?autoplay=1` : this.videoUrl
    }
  },
  methods: {
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    parseYouTubeVideoId (url) {
      const regExp = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?|shorts)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
      const match = url.match(regExp)
      return match ? match[1] : ''
    },
    setVideoAspect () {
      const video = this.$refs.videoPlayer
      if (video && video.videoHeight && video.videoWidth) {
        this.isPortrait = video.videoHeight > video.videoWidth
      }
    }
  }
}
</script>

<style scoped>
  .video-wrapper {
    position: relative;
    width: 100%;
    max-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 1rem 0;
  }

  .tw-responsive-video {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 80vh;
    width: 100%; /* Force full width */
    max-width: 100%; /* Prevent overflow */
    object-fit: contain;
    border-radius: 0.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  }

  .youtube-responsive {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    overflow: hidden;
    border-radius: 0.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  }

  .youtube-responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    .video-wrapper {
      padding: 0.5rem 0;
    }

    .youtube-responsive {
      padding-bottom: 75%;
    }
  }
  .cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
</style>
