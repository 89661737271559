<template>
  <BaseModal
    ref="modal"
    title="Media toevoegen"
    max-width="tw-max-w-xl"
  >
    <FormulateForm
      v-model="values"
      name="estimationReportGenerateForm"
      @submit="createEntityMedia"
    >
      <FormulateInput
        type="select"
        name="type"
        label="Type"
        :options="{ 0: 'Video', 1: 'Virtuele tour', 2: 'Plattegrond', 3: '3D render', 4: 'Logo Project', 5: 'Verticale video' }"
      />
      <FormulateInput
        type="text"
        name="identifier"
        label="Verwijzing"
        placeholder="Url of code"
      />
      <FormulateInput
        type="file"
        name="file"
        label="Upload"
        @input="handleRawFile"
      />
      <FormulateInput
        type="text"
        name="name"
        label="Naam"
      />
      <FormulateInput
        type="select"
        name="supplier"
        label="Leverancier"
        placeholder=""
        :options="{ 0: 'Around.media', 1: 'Zibber', 2: 'Blinqlab', 3: 'YouTube', 4: 'NodalView', 5: 'Matterport', 6: 'Walky' }"
        value=""
      />
      <FormulateInput
        type="submit"
        title="Opslaan"
        class="tw-flex tw-gap-x-2 tw-justify-end"
        :input-class="['tw-w-full']"
      >
        <i :class="['fas tw-mr-2', isLoading ? 'fa-spinner-third fa-spin' : 'fa-save']" />
        Opslaan
      </FormulateInput>
    </FormulateForm>
  </BaseModal>
</template>
<script>

export default {
  name: 'EntityMediaModal',
  props: {},
  data () {
    return {
      values: {
        type: '',
        supplier: '',
        name: ''
      },
      file: null
    }
  },
  methods: {
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    handleRawFile (fileList) {
      const files = fileList.fileList
      if (files && files.length > 0) {
        this.file = files[0] // This is the raw File object
        this.values.name = this.file.name
      } else {
        this.file = null
        this.values.name = ''
      }
    },
    createEntityMedia () {
      this.values.file = this.file ? this.file : null
      this.$emit('createEntityMedia', this.values)
      this.hide()
      this.values = {
        type: '',
        identifier: '',
        file: null,
        supplier: ''
      }
    }
  }
}
</script>
